<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("创建4E账号") }}</div>
      </div>
      <div class="form">
        <el-form
          ref="formRef"
          :rules="formRules"
          :model="formData"
          @submit.native.prevent
          :validate-on-rule-change="false"
          hide-required-asterisk
        >
          <el-form-item
            prop="account"
            :label="translateTitle('手机号或邮箱')"
            v-if="isPhone"
            class="mobile-form-item"
          >
            <el-dropdown trigger="click" placement="bottom-start">
              <div class="area">
                <span>{{ mobileAreaCode }}</span>
                <ArrowUpIcon />
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="search-input-wrapper">
                  <el-input v-model="mobilSearchValue" class="search-input">
                    <template #prefix>
                      <img width="15" :src="searchIcon" alt="" />
                    </template>
                  </el-input>
                </div>
                <div class="mobile-list">
                  <el-dropdown-item
                    v-for="item in areaCodeList"
                    :key="item.ssc_id"
                  >
                    <div class="mobile-item" @click="onAreaCodeItemClick(item)">
                      <span v-if="item[language]">{{ item[language] }} </span>
                      <span>+{{ item.phone_code }}</span>
                    </div>
                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>

            <el-input
              v-model="formData.account"
              :placeholder="translateTitle('请输入手机号或邮箱')"
            >
              <template #suffix>
                <ClearIcon
                  v-if="!!formData.account"
                  @click="formData.account = ''"
                />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item
            prop="account"
            :label="translateTitle('手机号或邮箱')"
            v-else
          >
            <el-input
              v-model="formData.account"
              :placeholder="translateTitle('请输入手机号或邮箱')"
            >
              <template #suffix>
                <ClearIcon
                  v-if="!!formData.account"
                  @click="formData.account = ''"
                />
              </template>
            </el-input>
          </el-form-item>

          <div>
            <div class="invite-code" @click="showInviteCode = !showInviteCode">
              <span>{{ translateTitle("邀请码（选填）") }}</span>
              <ArrowUpIcon class="icon" :class="{ down: showInviteCode }" />
            </div>
            <el-form-item>
              <el-input
                v-if="showInviteCode"
                v-model="inviteCode"
                :disabled="disabledChangeInviteCode"
                :placeholder="translateTitle('请输入邀请码')"
                @click.stop
              >
                <template #suffix>
                  <ClearIcon
                    v-if="!!inviteCode && !disabledChangeInviteCode"
                    @click="inviteCode = ''"
                  />
                </template>
              </el-input>
            </el-form-item>
          </div>
        </el-form>

        <div class="privacy">
          {{ translateTitle("注册即表示我同意并理解4E的") }}
          <a :href="serviceUrl" target="_blank">{{
            translateTitle("用户协议")
          }}</a
          >{{ translateTitle("和")
          }}<a :href="privacyUrl" target="_blank">
            {{ translateTitle("《隐私条款》") }}
          </a>
        </div>

        <el-button
          class="next-btn"
          type="primary"
          :disabled="!formData.account"
          @click="onNext"
          >{{ translateTitle("下一步") }}</el-button
        >
        <div class="other-btn">
          <span class="default">{{ translateTitle("已有账号？") }}</span>
          <span @click="$router.push(`/login/${language}`)">{{
            translateTitle("去登录")
          }}</span>
        </div>
      </div>
    </div>
    <Geetest :isGeet="isShowGeet" :isRegister="true" @geetParam="success" />
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import ClearIcon from "@/components/icons/ClearIcon.vue";
import Geetest from "@/components/Geetest.vue";
import { UCenterRequest } from "@/model/UCenterService";
import { apiGetStaticTableInfo } from "@/model/settings";
import ArrowUpIcon from "@/components/icons/ArrowUpIcon.vue";
import searchIcon from "@/assets/img/users/search.png";
export default {
  components: {
    ClearIcon,
    ArrowUpIcon,
    Geetest,
  },
  data() {
    return {
      logoIcon,
      searchIcon,
      showInviteCode: true,
      inviteCode: "",
      isShowGeet: false,
      disabledChangeInviteCode: false,

      formData: {
        account: "",
      },
      isInputPassword: false,
      mobileAreaCode: "+86",
      staticSmsCountry: {},
      mobilSearchValue: "",
      serviceUrl: "",
      privacyUrl: "",
    };
  },
  computed: {
    language() {
      return this.$store.state.user.language;
    },
    isPhone() {
      if (!this.formData.account) return false;
      const reg = /^[0-9]*[1-9][0-9]*$/;
      const text = this.formData.account.substring(0, 3);
      if (this.formData.account.includes("@")) return false;
      return !!(
        text.length >= 3 &&
        reg.test(text) &&
        reg.test(this.formData.account)
      );
    },
    areaCodeList() {
      if (this.mobilSearchValue == "") {
        return this.staticSmsCountry;
      }
      let tempList = {};
      for (const key in this.staticSmsCountry) {
        const temp = this.staticSmsCountry[key];
        if (
          temp[this.language] &&
          temp.phone_code &&
          [
            temp[this.language].toLowerCase(),
            temp.phone_code.toString().toLowerCase(),
          ]
            .join("")
            .includes(this.mobilSearchValue)
        ) {
          tempList[key] = this.staticSmsCountry[key];
        }
      }
      console.log("tempList", tempList);
      return tempList;
    },
    formRules() {
      return {
        account: [
          {
            required: true,
            message: this.translateTitle("邮箱/手机号不能为空"),
            trigger: "blur",
          },
          this.isPhone
            ? {}
            : {
                trigger: "blur",
                validator: (rule, value, callback) => {
                  if (!value) {
                    return callback(
                      new Error(this.translateTitle("邮箱/手机号/不能为空"))
                    );
                  }
                  if (this.isPhone) {
                    callback();
                    return;
                  }
                  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                  if (reg.test(value)) {
                    callback();
                    return;
                  }
                  callback(
                    new Error(
                      this.translateTitle(
                        "邮箱地址无效，仅限使用数字、字母或“_”字符"
                      )
                    )
                  );
                },
              },
        ],
      };
    },
  },
  created() {
    const { params } = this.$route;
    if (params.val) {
      this.formData.account = params.val;
    }
    const clauseList = JSON.parse(sessionStorage.getItem("clause"));
    if (clauseList[0].language) {
      this.serviceUrl = clauseList[0].language[`url_${this.language}`];
    } else {
      this.serviceUrl = clauseList[0].url;
    }
    if (clauseList[1].language) {
      this.privacyUrl = clauseList[1].language[`url_${this.language}`];
    } else {
      this.privacyUrl = clauseList[1].url;
    }
    this.getStaticSmsCountry();
    this.inviteCode = this.$route.query.ref;

    const fixedParams = this.getFixedParams();

    if (fixedParams) {
      if (fixedParams.is_lock_fixed_params === 1) {
        this.inviteCode = fixedParams.domain_fixed_params;
      } else {
        this.inviteCode = this.inviteCode || fixedParams.domain_fixed_params;
      }
    }
    if (this.inviteCode) {
      this.inviteCode = this.inviteCode;
      this.showInviteCode = true;
      this.disabledChangeInviteCode = true;
    }
    if (window.location.hostname.includes("4e.inc")) {
      this.inviteCode = "FCPXE4";
      this.showInviteCode = false;
      this.disabledChangeInviteCode = true;
    }
  },
  methods: {
    getFixedParams() {
      try {
        const configDataStr = window.localStorage.getItem("four_e_site_config");
        const configData = JSON.parse(configDataStr);
        return configData.data.fixed_params;
      } catch (e) {
        return null;
      }
    },
    async success(params) {
      console.log("params", params);
      this.loading = true;
      const area =
        this.mobileAreaCode[0] === "+"
          ? this.mobileAreaCode.substring(1)
          : this.mobileAreaCode;
      if (this.isPhone) {
        const res = await UCenterRequest.checkMobile({
          area,
          mobile: this.formData.account,
          ...params,
        });
        if (res.status === 200) {
          this.showNext = true;
          this.tokenRegister = res.data.token_register;
          this.$router.push({
            name: "RegisterVerify",

            query: {
              inviteCode: this.inviteCode,
              tokenRegister: res.data.token_register,
              mobile: this.formData.account,
              area: area,
            },
          });
        }
      } else {
        const res = await UCenterRequest.checkEmail({
          email: this.formData.account,
          ...params,
        });
        if (res.status === 200) {
          this.showNext = true;
          this.tokenRegister = res.data.token_register;
          this.$router.push({
            name: "RegisterVerify",

            query: {
              inviteCode: this.inviteCode,
              tokenRegister: res.data.token_register,
              email: this.formData.account,
            },
          });
        }
      }
      this.loading = false;
    },
    async getStaticSmsCountry() {
      const { status, data } = await apiGetStaticTableInfo(2);
      if (status == 200) {
        this.staticSmsCountry = JSON.parse(JSON.stringify(data));
      }
    },
    onNext() {
      this.$refs.formRef.validate(async valid => {
        if (valid) {
          this.isShowGeet = !this.isShowGeet;
        }
      });
    },
    onAreaCodeItemClick(item) {
      this.mobileAreaCode = "+" + item.phone_code;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/common.scss";

.next-btn {
  margin-top: 10px !important;
}

.privacy {
  margin-top: 76px !important;
  color: var(--Light04-text-c_t01, var(---c_t02, #5c5f69));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  margin-top: 14px;

  a {
    color: var(--Light04-text-c_t01, var(--7-c_m, #3348f6));
  }
}

.area {
  display: flex;
  height: 44px;
  align-items: center;
}

.mobile-list {
  width: 335px;
  height: 258px;
  overflow: auto;

  .mobile-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    color: var(--7-c_t01, #0d0e0e);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
}
</style>
